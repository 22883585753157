import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";
import TickIcon from "@/components/Common/Icons/TickIcon";
import CrossIcon from "@/components/Common/Icons/CrossIcon";
import AmenityIcon from "@/components/Common/Icons/AmenityIcon";

export default {
    name: 'SinglePropertySpecification',
    props: {
        amenities: {
            type: Array,
            default: null
        },
        allAmenities: {
            type: Array,
            default: null
        }
    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox,
        TickIcon,
        CrossIcon,
        AmenityIcon,
    },
    data() {
        return {
            propertyAmenitiesIdList: null,
        }
    },
    methods: {
        hasAmenity(amenity) {
            if (this.propertyAmenitiesIdList == null) {
                this.propertyAmenitiesIdList = this.amenities.map(amenity => amenity.id);
            }
            return this.propertyAmenitiesIdList.includes(amenity.id);
        }
    }
}
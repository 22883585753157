import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";
import PreLoader from "@/components/Common/PreLoader";
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'SinglePropertyMarketplaceCarousel',
    props: {
        products: {
            type: Array,
            default: []
        },
        missingAmenities: {
            type: Array,
            default: []
        },
    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox,
        MarketplaceAd,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        PreLoader
    },
    mounted() {
    },
    data: () => ({
        // carousel settings
        settings: {
            itemsToShow: 2.1,
            snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
            // 700px and up
            700: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            // 1024 and up
            1024: {
                itemsToShow: 6.3,
                snapAlign: 'start',
            },
        },
    }),
}
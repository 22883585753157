
export default {
    name: 'Pagination',
    props: {
        size: {
            type: String,
            default: 'small'
        },
        pages: {
            type: Number,
            default: 0
        },
        current: {
            type: Number,
            default: 1
        },
        route: {
            type: String,
            default: ''
        },
        routeParams: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            pagesList: 0
        }
    },
    methods: {
        selectPage(number) {
            if (number === '...') {
                return;
            }

            this.$emit('pageChanged', number)
            this.updatePagesList();
        },
        next() {
            if (this.$props.current >= this.$props.pages) {
                return;
            }
            this.$emit('pageChanged', this.$props.current + 1);
        },
        prev() {
            if (this.$props.current <= 1) {
                return;
            }
            this.$emit('pageChanged', this.$props.current - 1);
        },
        updatePagesList() {
            if (this.$props.pages > 10) {
                this.pagesList = [];
                if (this.$props.current < 3 || this.$props.current >= this.$props.pages - 3) {
                    this.pagesList = [1,2,3];
                } else {
                    this.pagesList.push(this.$props.current - 1);
                    this.pagesList.push(this.$props.current);
                    this.pagesList.push(this.$props.current + 1);
                }
                this.pagesList.push('...');
                this.pagesList.push(this.$props.pages - 3);
                this.pagesList.push(this.$props.pages - 2);
                this.pagesList.push(this.$props.pages - 1);
            }
        }
    },
    mounted() {
        this.pagesList = this.$props.pages;
        this.updatePagesList();
    }
}
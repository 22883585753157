import {GoogleMap, Marker} from 'vue3-google-map';
import mapOptions from "@/views/SearchResult/MapOptions";

export default {
    name: 'SinglePropertyDescription',
    props: {
        description: {
            type: String,
            default: ''
        },
        lat: {
            type: Number,
        },
        lng: {
            type: Number,
        },
    },
    components: {
        GoogleMap,
        Marker,

    },
    setup() {
        const mapOption = mapOptions;
        return {mapOption};
    },
    data() {
        return {
            // Map
            mapZoom: 15,
            mapCenter: {lat: 42.49988365271612, lng: 25.231609612925894},
            markers: [],
        }
    },
    mounted() {
        this.mapCenter =  {lat: this.$props.lat, lng: this.$props.lng};
        this.loadMarkers();
    },
    methods: {
        getMarkerOptions(lat, lng) {
            const fullUrl = location.protocol + '//' + location.host;

            return {
                position: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                },
                icon: {
                    url: fullUrl + '/static/marker-pin.png',
                    size: {width: 32, height: 32},
                    anchor: {x: 20, y: 20},
                    // scaledSize: { width: 40, height: 40 },
                    // labelOrigin: { x: 20, y: -10 },
                },
            };
        },
        loadMarkers() {
            this.markers.push(this.getMarkerOptions(this.$props.lat, this.$props.lng));
        },
    }
}
import PremiumLabelRoommate from "@/components/Common/Labels/PremiumLabelRoommate";
import UniversityIcon from "@/components/Common/Icons/UniversityIcon.vue";

export default {
    name: 'RoommateAd',
    props: {
        adtype: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        city: {
            type: String,
            default: ''
        },
        location: {
            type: String,
            default: ''
        },
        university: {
            type: String,
            default: null
        },
        date: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        price: {
            type: String,
            default: ''
        },
        thumbsize: {
            type: String,
            default: 'regular'
        },
        image: {
            type: String,
            default: null
        }
    },
    components: {
        UniversityIcon,
        PremiumLabelRoommate
    },
    mounted() {
    }
}
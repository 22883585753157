<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="location-main-title">{{ $t('property-location-title') }}</div>
    <div class="single-property-map">
      <GoogleMap style="width: 100%;
                   height: 100%" api-key="AIzaSyB4WqbVck19lMj4zvEBU9Y_CPILPW_237Q" :zoom="mapZoom" :center="mapCenter"
                 :styles="mapOption" :disableDefaultUi="true">
        <Marker v-for="(marker, index) in markers" v-bind:key="'mk' + index" :options="marker"/>
      </GoogleMap>
    </div>
    <div class="map-description"> {{ description }}</div>
  </div>

</template>

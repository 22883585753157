<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div class="single-property-marketplace-carousel">
        <div class="location-main-title">{{ $t('furnish-this-property') }}</div>
        <div class="property-marketplace-nav d-flex slider">
            <div class="me-2 slider__item" v-for="amenity in missingAmenities">
                <router-link :to="{name: 'MarketplaceCategoryPage', params: {id: amenity.categoryId}}">
                    {{ $t(amenity.name) }}
                </router-link>
            </div>
        </div>
        <div class="marketplace-carousel">
            <Carousel v-bind="settings" :breakpoints="breakpoints">
                <Slide v-for="(product, product_index) in products" :key="product_index">
                    <div class="slider_item">
                        <MarketplaceAd
                            :id="product.id"
                            :labelnew="true /* todo:! */"
                            :labeldiscount="true"
                            :title="product.title"
                            :category="product.categories.length > 0 ? $t(product.categories[0].title) : ''"
                            :regularamount="product.price"
                            :discountedamount="product.promoPrice"
                            :image="product.mainImage !== null ? product.mainImage.src : ''"
                        />
                    </div>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>


<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{  'ad-type-premium': adtype === 'premium',
    'thumb-size-regular': thumbsize === 'regular',
    'thumb-size-large': thumbsize === 'large',
  }">
    <div class="roommate-ad-container">
      <div class="row">
        <div class="col-md-4" v-if="image">
          <div class="thumb-frame ">
            <div class="thumb-image" v-bind:style="{'background-image': 'url(/storage/' + image + ')'}">
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="roommate-ad-text ">
            <div class="">
              <PremiumLabelRoommate class="premium-label" v-if="adtype === 'premium'"/>
            </div>
            <div class="roommate-ad-title ">
              {{ title }}
            </div>
            <div class="row gx-0">
              <div class="roommate-info-item col-md-auto">
                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_206_98915)">
                    <ellipse cx="6.5" cy="12.4" rx="5.6875" ry="2.8" stroke="#434D56" stroke-width="1.5"
                             stroke-linejoin="round"/>
                    <ellipse cx="6.5" cy="4" rx="3.25" ry="3.2" stroke="#434D56" stroke-width="1.5"
                             stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_206_98915">
                      <rect width="13" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span class="roommate-text-item">{{ name }}</span>
              </div>
              <div class="roommate-info-item col-md-auto">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.1741 0.888885L14.7166 8.43136" stroke="#434D56" stroke-width="1.33333"
                        stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                    d="M8.42987 2.14596L5.60144 4.97439L4.18723 6.38861L3.48012 7.09571L1.51594 7.80282L7.80133 14.0882L8.50844 12.124L9.21554 11.4169L10.6298 10.0027L13.4582 7.17428"
                    stroke="#434D56" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.66071 10.9455L0.88947 14.7167" stroke="#434D56" stroke-width="1.33333"
                        stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="roommate-text-item">{{ city }}, {{ location }}</span>
              </div>
              <div class="roommate-info-item col-md-auto">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_206_98916)">
                    <path d="M5.25 3L7.5 0.75M7.5 0.75L9.75 3M7.5 0.75L7.5 9.75" stroke="#434D56" stroke-width="1.125"
                          stroke-linecap="round" stroke-linejoin="round"/>
                    <path
                      d="M4.125 5.25L3.75 5.25C2.09315 5.25 0.75 6.59315 0.75 8.25L0.75 11.25C0.75 12.9069 2.09315 14.25 3.75 14.25L11.25 14.25C12.9069 14.25 14.25 12.9069 14.25 11.25L14.25 8.25C14.25 6.59315 12.9069 5.25 11.25 5.25L10.875 5.25"
                      stroke="#434D56" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_206_98916">
                      <rect width="15" height="15" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <span class="roommate-text-item">{{ date }}</span>
              </div>
              <div class="roommate-info-item col-md-auto" v-if="university">
                <UniversityIcon/>
                <span class="roommate-text-item">{{ university }}</span>
              </div>
            </div>
            <div class="">
              <p class="roommate-ad-para ">
                {{ description }}
              </p>
            </div>
            <div class="">
              <div class="roommate-price-tag">
                <span class="price-amount"> {{ price }}</span>
                <span class="ps-4">{{ $t('bgn-per-month') }}</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


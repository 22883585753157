import Button from "@/components/Common/Buttons/Button/Button";
import HeartIconOrange from "@/components/Common/Icons/HeartIconOrange";
import AddToFavourites from "@/components/Components/AddToFavourites/AddToFavourites";
import TrustedAgenciesIcon from "@/components/Common/Icons/TrustedAgenciesIcon.vue";

export default {
    name: 'RoommateAdContact',
    props: {
        date: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        views: {
            type: String,
            default: ''
        },
        amount: {
            type: String,
            default: ''
        },
        deposit: {
            type: String,
            default: ''
        },
        property: {
            type: Object,
            default: null
        },
        agency: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth < 800;
        }
    },
    components: {
        TrustedAgenciesIcon,
        AddToFavourites,
        Button,
        HeartIconOrange
    },
    data() {
        return {
            isPhoneShown: false
        }
    },
    methods: {
        showPhone() {
            this.isPhoneShown = true;
        }
    }
}